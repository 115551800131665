import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { Fragment, useEffect, useMemo } from 'react';

import { useDialog } from '@/context/dialog';
import { useTokenStatsV2 } from '@/hooks/use-token-stats-v2';
import useTokens from '@/hooks/use-tokens';

import StarredPairListItem from './StarredPairListItem';

import type { FromToWithInverse } from '@/hooks/use-starred-pairs';
import type { MaybePromise } from '@/types';

export interface StarredPairsDialogProps {
  open: boolean;
  onClose: (open: false) => void;
  onSelectFromTo: ({ from, to, inverse }: FromToWithInverse) => MaybePromise<void>;
}

export default function StarredPairsDialog({
  open,
  onClose,
  onSelectFromTo,
}: StarredPairsDialogProps) {
  const { t: ts } = useTranslation('swap');

  const { tokenAddressToToken } = useTokens({ withImaginaryFiats: true });
  const { starredPairs, toggleStarred, reloadStarredPairs } = useDialog();

  useEffect(() => {
    if (open) {
      reloadStarredPairs([]);
    }
  }, [open, reloadStarredPairs]);

  const tokenAddressSet = useMemo(
    () => new Set(starredPairs.flatMap(({ from, to }) => [from, to])),
    [starredPairs],
  );

  const { tokenStats } = useTokenStatsV2({ tokenAddrs: Array.from(tokenAddressSet) });

  const starredPairsWithDetails = useMemo(() => {
    if (!tokenAddressToToken || !tokenStats.length) return [];

    return starredPairs.flatMap(({ from, to, inverse }) => {
      const fromStats = tokenStats.find(({ address }) => address === from);
      const toStats = tokenStats.find(({ address }) => address === to);

      if (!tokenAddressToToken[from] || !tokenAddressToToken[to] || !fromStats || !toStats) {
        return [];
      }

      return [
        {
          fromToken: tokenAddressToToken[from],
          toToken: tokenAddressToToken[to],
          inverse,
          prices: !inverse
            ? [toStats.prices[0] / fromStats.prices[0], toStats.prices[1] / fromStats.prices[1]]
            : [fromStats.prices[0] / toStats.prices[0], fromStats.prices[1] / toStats.prices[1]],
        },
      ];
    });
  }, [tokenAddressToToken, starredPairs, tokenStats]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[1500] overflow-y-auto p-4 sm:p-6 md:p-20"
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 transition-all bg-dialog-background" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={clsx(
              'mx-auto max-w-2xl max-h-full transform divide-y divide-opacity-10 overflow-hidden rounded-xl shadow-2xl ring-1 transition-all',
              'divide-slate-600 bg-gray-900 ring-slate-50/5',
            )}
          >
            <div className="px-4 py-3 flex justify-between items-center">
              <label className="font-medium text-lg text-slate-200">{ts('Starred Pairs')}</label>
              <button
                className={clsx(
                  'rounded border px-1.5 py-px text-xs font-medium tracking-tight hover:opacity-70 outline-none',
                  'border-slate-600 bg-slate-900 text-slate-200',
                )}
                onClick={() => onClose(false)}
              >
                ESC
              </button>
            </div>

            {starredPairsWithDetails && (
              <ul
                className="scroll-py-2 divide-y overflow-y-auto divide-slate-400/10"
                style={{ maxHeight: 'min(640px, 80vh)' }}
              >
                <li className="p-2">
                  <ul className="text-sm text-slate-200">
                    {starredPairsWithDetails.map((item, idx) => (
                      <StarredPairListItem
                        key={`starred-pairs-${idx}`}
                        pair={{
                          tokens: !item.inverse
                            ? [item.toToken, item.fromToken]
                            : [item.fromToken, item.toToken],
                          prices: item.prices as [number, number],
                        }}
                        onToggleStarred={() => {
                          toggleStarred({
                            from: item.fromToken.address,
                            to: item.toToken.address,
                            inverse: item.inverse,
                          });
                        }}
                        onSelectPair={() => {
                          onSelectFromTo({
                            from: item.fromToken.address,
                            to: item.toToken.address,
                            inverse: item.inverse,
                          });
                          onClose(false);
                        }}
                      />
                    ))}
                  </ul>
                </li>
              </ul>
            )}

            {/* TODO: Change Content */}
            {starredPairsWithDetails?.length === 0 && (
              <div className="py-14 px-6 text-center sm:px-14">
                <ExclamationCircleIcon
                  className="mx-auto h-6 w-6 text-slate-50/40"
                  aria-hidden="true"
                />
                <p className="mt-4 text-sm text-slate-50">{ts('No starred pairs')}</p>
              </div>
            )}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
