import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import CopyCompletedIcon from '@/components/vector/CopyCompletedIcon';
import CopyIcon from '@/components/vector/CopyIcon';
import DeleteIcon from '@/components/vector/DeleteIcon';
import EditCompleteIcon from '@/components/vector/EditCompleteIcon';
import EditIcon from '@/components/vector/EditIcon';
import StarIcon from '@/components/vector/StarIcon';
import UndeleteIcon from '@/components/vector/UndeleteIcon';
import { copyToClipboard } from '@/utils/copy-to-clipboard';

import s from './AddressBookV2.module.css';

import type { Expand } from '@/types';

export type AddressBook = {
  name: string;
  address: string;
};

type InnerAddressBook = Expand<AddressBook & { isDeleted: boolean }>;

export type AddressBookV2DialogProps = {
  open: boolean;
  addressBook: AddressBook[];
  setAddressBook: (value: AddressBook[]) => void;
  routerPushHandler: (address: string) => void;
  onClose: () => void;
};

export default function AddressBookV2Dialog({
  open,
  addressBook,
  setAddressBook,
  routerPushHandler,
  ...props
}: AddressBookV2DialogProps) {
  const dummyButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('addressBook');

  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const [isEditing, setIsEditing] = useState<boolean[]>([]);
  const [innerAddressBook, setInnerAddressBook] = useState<InnerAddressBook[]>([]);
  const [copyCompleted, setCopyCompleted] = useState<boolean[]>([]);

  useEffect(() => {
    setCopyCompleted(addressBook.map(() => false));
  }, [addressBook]);

  useEffect(() => {
    setInnerAddressBook(addressBook.map((item) => ({ ...item, isDeleted: false })));
    setIsEditing(addressBook.map(() => false));
  }, [addressBook]);

  const onClose = useCallback(() => {
    setTimeout(() => {
      setInnerAddressBook(addressBook.map((item) => ({ ...item, isDeleted: false })));
      setIsEditing(addressBook.map(() => false));
    }, 300);

    props.onClose();
  }, [props, addressBook]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[1500] overflow-y-auto"
        onClose={onClose}
        initialFocus={dummyButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 transition-all bg-dialog-background" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={clsx(
              'max-w-[92%] xs:max-w-lg w-full p-8 transform overflow-hidden rounded-xl shadow-2xl transition-all fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
              'bg-gray-900 ring-1 ring-slate-50/5',
            )}
          >
            <div className="flex flex-row items-center space-x-2">
              <StarIcon className="text-yellow-500" />
              <h2 className="text-2xl font-bold text-gray-300">{t('Address book')}</h2>
            </div>
            <div className="mt-8 flex flex-col space-y-4 overflow-x-auto p-[1px] max-h-[300px]">
              {innerAddressBook.length === 0 ? (
                <p className="text-center font-semibold text-gray-300">
                  {t('There is no address added in address book')}
                </p>
              ) : (
                innerAddressBook.map(({ name, address, isDeleted }, idx) => (
                  <div key={address} className="relative">
                    <div className="flex flex-row items-center justify-between space-x-4">
                      <input
                        data-tooltip-id="global-tooltip"
                        data-tooltip-content={t('Open this address')}
                        data-tooltip-hidden={isEditing[idx] || isDeleted}
                        type={isEditing[idx] ? 'text' : 'button'}
                        defaultValue={name}
                        placeholder={t('Set a name for this address')}
                        className={clsx(s['content'], 'truncate', {
                          'cursor-pointer hover:opacity-70': !isEditing[idx] && !isDeleted,
                          'opacity-30 cursor-not-allowed line-through decoration-red-600 select-none':
                            isDeleted,
                        })}
                        onChange={(e) => {
                          const next = [...innerAddressBook];
                          next[idx].name = e.target.value;
                          setInnerAddressBook(next);
                        }}
                        onClick={() => {
                          if (isEditing[idx] || isDeleted) return;

                          routerPushHandler(address);
                          onClose();
                        }}
                        onFocus={(e) => {
                          setTimeout(function () {
                            if (!e.target || !e.target.value) return;
                            if (e.target.getAttribute('type') !== 'text') return;

                            e.target.setSelectionRange(
                              e.target.value.length,
                              e.target.value.length,
                            );
                          }, 0);
                        }}
                        disabled={isDeleted}
                      />

                      <button
                        data-tooltip-id="global-tooltip"
                        data-tooltip-content={
                          isEditing[idx] ? t('Undo name modification') : t('Edit a name')
                        }
                        data-tooltip-hidden={isDeleted}
                        className={clsx({
                          'opacity-30 cursor-not-allowed !hover:opacity-30': isDeleted,
                          'hover:opacity-70': !isDeleted,
                        })}
                        onClick={(e) => {
                          if (isDeleted) return;

                          if (!isEditing[idx])
                            (e.currentTarget.previousElementSibling as HTMLInputElement).focus();

                          if (isEditing[idx]) {
                            const next = [...innerAddressBook];
                            next[idx].name = addressBook[idx].name;
                            setInnerAddressBook(next);
                          }

                          setIsEditing((prev) => {
                            const next = [...prev];
                            next[idx] = !next[idx];
                            return next;
                          });
                        }}
                      >
                        {isEditing[idx] ? (
                          <EditCompleteIcon className={clsx(s['icon-color'], 'w-6 h-6')} />
                        ) : (
                          <EditIcon className={clsx(s['icon-color'], 'w-6 h-6')} />
                        )}
                      </button>

                      <button
                        data-tooltip-id="global-tooltip"
                        data-tooltip-content={isDeleted ? t('Undelete') : t('Delete')}
                        onClick={() => {
                          setInnerAddressBook(
                            innerAddressBook.map((item, _idx) => {
                              if (_idx === idx) {
                                return {
                                  ...item,
                                  isDeleted: !item.isDeleted,
                                };
                              }
                              return item;
                            }),
                          );
                        }}
                        className="hover:opacity-70"
                      >
                        {isDeleted ? (
                          <UndeleteIcon width={24} height={24} className={s['icon-color']} />
                        ) : (
                          <DeleteIcon width={24} height={24} className={s['icon-color']} />
                        )}
                      </button>
                    </div>

                    <div className="mt-2 pl-2 flex flex-row items-center space-x-4 mr-20">
                      <span
                        className={clsx('text-white text-sm truncate', {
                          'opacity-30 line-through decoration-red-600 select-none cursor-not-allowed':
                            isDeleted,
                        })}
                      >
                        {address}
                      </span>

                      <button
                        data-tooltip-id="global-tooltip"
                        data-tooltip-content={t('Copy Address')}
                        data-tooltip-hidden={isDeleted}
                        className={clsx({
                          'hover:opacity-70': !isDeleted,
                          'opacity-30 cursor-not-allowed': isDeleted,
                        })}
                        onClick={() => {
                          copyToClipboard(address);
                          setCopyCompleted((prev) => {
                            const next = [...prev];
                            next[idx] = true;
                            return next;
                          });

                          setTimeout(() => {
                            setCopyCompleted((prev) => {
                              const next = [...prev];
                              next[idx] = false;
                              return next;
                            });
                          }, 1_000);
                        }}
                      >
                        {!copyCompleted[idx] ? (
                          <CopyIcon width={16} height={16} className={s['icon-color']} />
                        ) : (
                          <CopyCompletedIcon className="text-scnr w-3 h-3 xs:w-4 xs:h-4" />
                        )}
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="w-full text-right mt-8">
              <button className={clsx(s['button'], s['close-button'])} onClick={onClose}>
                {t('Close')}
              </button>
              <button
                ref={saveButtonRef}
                className={clsx(s['button'], s['save-button'])}
                onClick={() => {
                  setAddressBook([...innerAddressBook.filter(({ isDeleted }) => !isDeleted)]);
                  onClose();
                }}
              >
                {t('Save')}
              </button>
            </div>
          </div>
        </Transition.Child>
        <style jsx>{`
          .hide-scrollbar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }
          .hide-scrollbar::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera */
          }
        `}</style>
      </Dialog>
    </Transition.Root>
  );
}
