import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ToggleButton from '@/components/custom/ToggleButton';
import { useCurrencyContext } from '@/context/currency';

export type CexPriceDisplaySetting = {
  activate: boolean;
  otherCurrency: boolean;
  diffPercentage: boolean;
};

export type CexPriceDisplaySetupDialogProps = {
  open: boolean;
  cexPriceDisplaySetting: CexPriceDisplaySetting;
  setCexPriceDisplaySetting: (value: CexPriceDisplaySetting) => void;
  onClose: () => void;
};

export default function CexPriceDisplaySetupDialog({
  open,
  cexPriceDisplaySetting,
  setCexPriceDisplaySetting,
  ...props
}: CexPriceDisplaySetupDialogProps) {
  const dummyButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('pro');

  const { currencyType } = useCurrencyContext();

  const KEY_LIST = useMemo(
    () => [
      'Activate',
      `Display ${currencyType === 'KRW' ? 'USD($)' : 'KRW(₩)'}`,
      'Display % difference',
    ],
    [currencyType],
  );

  const {
    activate: _activate,
    otherCurrency: _otherCurrency,
    diffPercentage: _diffPercentage,
  } = cexPriceDisplaySetting;

  const [activate, setActivate] = useState<boolean>(false);
  const [otherCurrency, setOtherCurrency] = useState<boolean>(false);
  const [diffPercentage, setDiffPercentage] = useState<boolean>(false);

  useEffect(() => {
    setActivate(_activate);
    setOtherCurrency(_otherCurrency);
    setDiffPercentage(_diffPercentage);
  }, [_activate, _otherCurrency, _diffPercentage]);

  useEffect(() => {
    setCexPriceDisplaySetting({
      activate,
      otherCurrency,
      diffPercentage,
    });
  }, [activate, otherCurrency, diffPercentage, setCexPriceDisplaySetting]);

  const onClose = useCallback(() => {
    props.onClose();
  }, [props]);

  const settings = useMemo(
    () =>
      KEY_LIST.map((key, idx) => {
        return {
          key,
          value: idx === 0 ? activate : idx === 1 ? otherCurrency : diffPercentage,
          setValue: idx === 0 ? setActivate : idx === 1 ? setOtherCurrency : setDiffPercentage,
        };
      }),
    [activate, otherCurrency, diffPercentage, KEY_LIST],
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[1500] overflow-y-auto"
        onClose={onClose}
        initialFocus={dummyButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 transition-all bg-dialog-background" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="max-w-[92%] min-[360px]:max-w-[320px] w-full p-8 transform overflow-hidden rounded-xl shadow-2xl transition-all fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-gray-900 ring-1 ring-slate-50/5">
            <div className="w-full flex flex-row items-center justify-between border-b border-gray-700 pb-3 space-x-2">
              <h3 className="font-medium text-lg text-slate-200">{t('CEX Price Display Setup')}</h3>
              <button
                className="rounded border px-1.5 py-px text-xs font-medium tracking-tight hover:opacity-70 outline-none border-slate-600 bg-slate-900 text-slate-200"
                onClick={() => onClose()}
              >
                ESC
              </button>
            </div>
            <div className="flex flex-col space-y-3 mt-5">
              {settings.map(({ key, value, setValue }, idx) => {
                return (
                  <div
                    key={key}
                    className="flex flex-row items-center space-x-2 w-full justify-between"
                  >
                    <span
                      className={clsx('text-slate-200', {
                        'opacity-30 cursor-not-allowed': idx !== 0 && !activate,
                      })}
                    >
                      {t(key)}
                    </span>
                    <ToggleButton
                      setChecked={setValue}
                      checked={value}
                      disabled={idx !== 0 && !activate}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
