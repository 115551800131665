import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon, XCircleIcon, XIcon } from '@heroicons/react/outline';
import { PushPin, Question } from '@phosphor-icons/react';
import clsx from 'clsx';
import { matchSorter } from 'match-sorter';
import { Trans, useTranslation } from 'next-i18next';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import TokenIconV2 from '@/components/TokenIconV2';
import { SearchIcon } from '@/components/vector';
import { useWalletContext } from '@/context/wallet';
import { LOCAL_STORAGE_KEYS } from '@/defines/local-storage-keys';
import {
  GCKAIA_ADDRESS,
  IMAGINARY_FIAT_ADDRESS_MAP,
  SCNR_ADDRESS,
  ZERO_ADDRESS,
} from '@/defines/token-address';
import useBalancesV1 from '@/hooks/use-balances-v1';
import { useFilteredTokens } from '@/hooks/use-filtered-tokens';
import useForex from '@/hooks/use-forex';
import useLocalStorageV2 from '@/hooks/use-local-storage-v2';
import usePrices from '@/hooks/use-prices';
import useTokens from '@/hooks/use-tokens';

import s from './style.module.css';

import type { Token } from '@/lib/tokens';

const TOP_SORT_PRIORITY = [
  ...Object.values(IMAGINARY_FIAT_ADDRESS_MAP),
  ZERO_ADDRESS,
  GCKAIA_ADDRESS,
  SCNR_ADDRESS,
];

export type TokenSelectorDialogV3Props = {
  label: string;
  open: boolean;
  onClose: () => void;
  onSelectToken: (token: Token) => void;
  sortOption?: 'default' | 'balance';
  withImaginaryFiats?: boolean;
};

export function TokenSelectorDialogV3({
  label,
  open,
  onClose,
  onSelectToken,
  sortOption = 'default',
  withImaginaryFiats,
}: TokenSelectorDialogV3Props) {
  const { t: tt } = useTranslation('tokens');
  const { t: ts } = useTranslation('swap');

  const { wallet } = useWalletContext();

  const { tokens, tokenAddressToToken } = useTokens({ withImaginaryFiats, whitelistedOnly: true });
  const { tokens: tokensWithLowLiquidity } = useFilteredTokens({ liquidityThreshold: 100 });
  const { balances } = useBalancesV1({ account: wallet?.account ?? null, skip: !open });
  const { prices } = usePrices({ skip: !open });
  const { usdToReadableCurrency } = useForex();

  const [pinnedTokenAddresses, setPinnedTokenAddresses, reloadPinnedTokensFromLocalStorage] =
    useLocalStorageV2<string[]>(LOCAL_STORAGE_KEYS.favoriteTokens, []);

  const [query, setQuery] = useState('');
  const [currentIdx, setCurrentIdx] = useState(0);

  useEffect(() => {
    if (open) {
      reloadPinnedTokensFromLocalStorage([]);
    }
  }, [open, reloadPinnedTokensFromLocalStorage]);

  const pinnedTokens = useMemo(() => {
    const set = new Set(pinnedTokenAddresses);

    if (!tokensWithLowLiquidity) return set;

    tokensWithLowLiquidity.forEach(({ address }) => set.delete(address));

    return set;
  }, [pinnedTokenAddresses, tokensWithLowLiquidity]);

  const pinnedTokenMap = useMemo(() => {
    return Object.fromEntries(Array.from(pinnedTokens).map((address) => [address, true]));
  }, [pinnedTokens]);

  const togglePinnedToken = useCallback(
    (tokenAddress: string) => {
      if (!pinnedTokenAddresses) return;

      const isPinned = pinnedTokenAddresses.includes(tokenAddress);
      if (isPinned) {
        setPinnedTokenAddresses(pinnedTokenAddresses.filter((address) => address !== tokenAddress));
      } else {
        setPinnedTokenAddresses([...pinnedTokenAddresses, tokenAddress]);
      }
    },
    [pinnedTokenAddresses, setPinnedTokenAddresses],
  );

  // FIXME: This is a hack.
  // https://levelup.gitconnected.com/javascript-events-handlers-keyboard-and-load-events-1b3e46a6b0c3
  const debounceFlag = useRef(false);
  const debouncedKeyDown = (fn: Function) => (e: any) => {
    if (debounceFlag.current) return;
    debounceFlag.current = true;
    fn(e);
    setTimeout(() => (debounceFlag.current = false), 50);
  };

  const ignoreMouseEnterRef = useRef(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const calcBalanceAmountInUSDC = useCallback(
    (address: string) => {
      if (!balances || !prices || !tokenAddressToToken[address]) return 0;

      const balance = balances[address];
      const price = prices[address];
      const { decimals } = tokenAddressToToken[address];

      const amountInUSDC = (Number(balance ?? 0) * price) / 10 ** +(decimals ?? 18);

      return amountInUSDC;
    },
    [tokenAddressToToken, balances, prices],
  );

  const filteredTokens = useMemo(() => {
    // exclude krw because it is based on oUSDC and forex
    let ts = [
      ...tokens.filter((t) => t.address !== 'krw'),
      ...tokensWithLowLiquidity.filter((t) => t.address !== GCKAIA_ADDRESS),
    ];

    // sort by balance
    if (sortOption === 'balance' && balances && prices) {
      ts.sort((a, b) => calcBalanceAmountInUSDC(b.address) - calcBalanceAmountInUSDC(a.address));
    }

    // sort by query using matchSorter
    if (query) {
      ts = matchSorter(ts, query, {
        keys: [
          { key: (item) => item.symbol },
          { key: (item) => tt(item.address, '') },
          'name',
          { key: 'address', threshold: matchSorter.rankings.CONTAINS },
        ],
        baseSort: (a, b) => Number(b.item.whitelisted ?? 0) - Number(a.item.whitelisted ?? 0),
      });
    }

    // sort by pinned
    ts.sort(
      (a, b) => Number(pinnedTokens.has(b.address)) - Number(pinnedTokens.has(a.address)),
    ).sort((a, b) => {
      if (TOP_SORT_PRIORITY.includes(a.address) && TOP_SORT_PRIORITY.includes(b.address)) {
        return TOP_SORT_PRIORITY.indexOf(a.address) - TOP_SORT_PRIORITY.indexOf(b.address);
      }

      if (TOP_SORT_PRIORITY.includes(a.address)) {
        return -1;
      }

      return 0;
    });

    return ts;
  }, [
    tokens,
    query,
    balances,
    prices,
    sortOption,
    calcBalanceAmountInUSDC,
    tt,
    pinnedTokens,
    tokensWithLowLiquidity,
  ]);

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => {
        setQuery('');
        setCurrentIdx(0);
      }}
    >
      <Dialog
        as="div"
        className="fixed inset-0 z-[1500] overflow-y-auto p-4 sm:p-6 md:p-20"
        onClose={onClose}
        initialFocus={searchInputRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 transition-all bg-dialog-background" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="mx-auto max-w-2xl max-h-full transform divide-y divide-opacity-10 overflow-hidden rounded-xl shadow-2xl transition-all divide-gray-700 bg-gray-900">
            <div className="px-5 py-[18px] flex justify-between items-center">
              <label className="font-semibold text-lg text-white">{label}</label>
              <button className="rounded-full p-1 hover:opacity-70" onClick={onClose}>
                <XIcon className="h-6 w-6 text-gray-400" />
              </button>
            </div>
            <div>
              <div className="p-5">
                <div className="p-3 flex items-center rounded-lg bg-gray-800">
                  <SearchIcon
                    className="pointer-events-none h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  <input
                    ref={searchInputRef}
                    value={query}
                    className="h-6 outline-none flex-1 border-0 bg-transparent px-4 focus:ring-0 sm:text-sm text-white placeholder-gray-500"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                    onKeyDown={debouncedKeyDown((e: KeyboardEvent) => {
                      if (currentIdx === -1) return;
                      if (e.key === 'ArrowUp') {
                        e.preventDefault();
                        if (currentIdx === 0) return;

                        ignoreMouseEnterRef.current = true;
                        const elem = document.getElementById(`token-${currentIdx - 1}`);
                        elem?.scrollIntoView({ block: 'nearest' });
                        setCurrentIdx(currentIdx - 1);
                      } else if (e.key === 'ArrowDown') {
                        e.preventDefault();
                        if (!filteredTokens.length || currentIdx >= filteredTokens.length - 1)
                          return;

                        ignoreMouseEnterRef.current = true;
                        const elem = document.getElementById(`token-${currentIdx + 1}`);
                        elem?.scrollIntoView({ block: 'nearest' });
                        setCurrentIdx(currentIdx + 1);
                      } else if (e.key === 'Enter') {
                        e.preventDefault();
                        if (currentIdx === -1 || !filteredTokens.length) {
                          onClose();
                          return;
                        }

                        const token = filteredTokens[currentIdx];

                        if (!token) return;

                        if (!token.whitelisted) return;

                        onSelectToken(token);
                        onClose();
                        return;
                      }
                    })}
                  />
                  <button
                    className={clsx('rounded-full text-gray-400 hover:opacity-70', {
                      invisible: query === '',
                    })}
                    disabled={query === ''}
                    onClick={() => setQuery('')}
                  >
                    <XCircleIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>

              {filteredTokens.length > 0 ? (
                <ul
                  className={clsx(s['token-list'], 'scroll-py-2 overflow-y-auto')}
                  style={{ maxHeight: 'min(640px, calc(80vh - 55px))', minHeight: '120px' }}
                >
                  {filteredTokens.map((token, idx) => {
                    const balance = +(balances?.[token.address] ?? 0) / 10 ** +token.decimals;
                    return (
                      <li
                        className={clsx(s.dark, {
                          [s.focused]: idx === currentIdx,
                          [s.disabled]: !token.whitelisted,
                        })}
                        key={token.address}
                        id={`token-${idx}`}
                        onMouseEnter={() => {
                          if (ignoreMouseEnterRef.current) {
                            ignoreMouseEnterRef.current = false;
                            return;
                          }

                          setCurrentIdx(idx);
                        }}
                        onClick={() => {
                          if (!token.whitelisted) {
                            return;
                          }
                          onSelectToken(token);
                          onClose();
                        }}
                      >
                        <button disabled={!token.whitelisted}>
                          <TokenIconV2 address={token.address} height={40} width={40} />
                          <div>
                            <div className={s.main}>
                              <span>{token.symbol}</span>
                              {balance ? (
                                <span>
                                  {balance.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 7,
                                  })}
                                </span>
                              ) : null}
                            </div>
                            <div className={s.sub}>
                              <span>{tt(token.address, token.name)}</span>
                              {balance ? (
                                <span>
                                  {usdToReadableCurrency(calcBalanceAmountInUSDC(token.address))}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </button>
                        {!token.whitelisted ? (
                          <div
                            className="px-1 flex justify-center items-center"
                            data-tooltip-id="tooltip-disabled-token"
                          >
                            <Tooltip
                              id="tooltip-disabled-token"
                              className="ss-tooltip-base !w-[calc(100%-100px)]"
                              opacity={1}
                              content={tt(
                                'The token has been disabled because it has less than $1000 liquidity on any DEX integrated to Swapscanner.',
                              )}
                              place="left"
                            />
                            <Question className="h-4 w-4 text-gray-400" />
                          </div>
                        ) : null}
                        <button
                          className={clsx(s['pin-button'], {
                            invisible: TOP_SORT_PRIORITY.includes(token.address),
                          })}
                          disabled={!token.whitelisted}
                          onClick={(e) => {
                            e.stopPropagation();
                            togglePinnedToken(token.address);
                          }}
                        >
                          <PushPin
                            className="h-4 w-4 text-gray-400"
                            size={16}
                            weight={pinnedTokenMap[token.address] ? 'fill' : 'regular'}
                          />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div className="py-12 px-6 text-center sm:px-14">
                  <ExclamationCircleIcon
                    className="mx-auto h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <p className="mt-4 text-sm leading-6 text-white">
                    <Trans
                      t={ts}
                      i18nKey={
                        "We couldn't find any tokens with that term.<br />Check your search keyword or filter settings."
                      }
                    />
                  </p>
                </div>
              )}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
