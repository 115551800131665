import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AddressBookPopup from '@/components/modal/TokenTransferDialog/AddressBookPopup';
import TokenIconV2 from '@/components/TokenIconV2';
import { LinkIcon } from '@/components/vector';
import { ValidatedInput, ValidatedInputHandle } from '@/components/wallet/ValidatedInput';
import { useWalletContext } from '@/context/wallet';
import { VALID_ADDRESS_REGEX, VALID_FLOAT_REGEX } from '@/defines/consts';
import { LOCAL_STORAGE_KEYS } from '@/defines/local-storage-keys';
import { ZERO_ADDRESS } from '@/defines/token-address';
import useForex from '@/hooks/use-forex';
import useLocalStorageV2 from '@/hooks/use-local-storage-v2';
import { useWeb3Transaction } from '@/lib/useWeb3Transaction';
import bigIntMax from '@/utils/bigIntMax';
import denominateAmount from '@/utils/denominateAmount';
import numerateAmount from '@/utils/numerateAmount';

import s from './index.module.css';

import type { TokenWithInformation } from '@/lib/tokens';

export type TokenTransferDialogProps = {
  open: boolean;
  token: TokenWithInformation | null;
  onClose: () => void;
};

export default function TokenTransferDialog({
  open,
  token,

  ...props
}: TokenTransferDialogProps) {
  const { t } = useTranslation('portfolio');
  const { t: tc } = useTranslation('common');

  const { wallet } = useWalletContext();
  const { usdToReadableCurrency } = useForex();

  const [transferNetwork, setTransferNetwork] = useState<string>('kaia');
  const [showAddressbook, setShowAddressbook] = useState<boolean>(false);

  const currentPrice = token?.priceUSDC;
  const [maxAmount, setMaxAmount] = useState<boolean>(false);

  const decimals = useMemo(() => parseInt(token?.decimals ?? '0', 10), [token?.decimals]);
  const availableMaxAmount = useMemo(() => {
    if (!token?.rawBalance) {
      return undefined;
    }

    let maxBalance = BigInt(token.rawBalance);

    // Native token with decimals 18
    if (token?.address === ZERO_ADDRESS) {
      const safeAmount = maxBalance - BigInt(10 ** 18);
      maxBalance = bigIntMax(safeAmount, 0n);
    }

    return maxBalance;
  }, [token?.address, token?.rawBalance]);

  const [receiverAddress, setReceiverAddress] = useState<string>('');
  const [receiverAddressError, setReceiverAddressError] = useState<boolean>(false);
  const receiverAddressInputRef = useRef<ValidatedInputHandle>(null);

  const [transferAmount, setTransferAmount] = useState<string>('');
  const [transferAmountError, setTransferAmountError] = useState<boolean>(false);
  const transferAmountInputRef = useRef<ValidatedInputHandle>(null);

  const numeratedAmount = useMemo(
    () => numerateAmount({ amount: transferAmount, decimals }),
    [transferAmount, decimals],
  );

  const onClose = useCallback(() => {
    props.onClose();

    setMaxAmount(false);
    setReceiverAddress('');
    setReceiverAddressError(false);
    setTransferAmount('');
    setTransferAmountError(false);
    setTransferNetwork('kaia');

    receiverAddressInputRef.current?.cleanup();
    transferAmountInputRef.current?.cleanup();
  }, [props]);

  const validateTransferAmount = useCallback(
    (value: string) => {
      if (!value || !availableMaxAmount) {
        return false;
      }

      if (!VALID_FLOAT_REGEX.test(value.replaceAll(',', ''))) {
        return false;
      }

      if (BigInt(numerateAmount({ amount: value, decimals })) > availableMaxAmount) {
        return false;
      }
      return true;
    },
    [availableMaxAmount, decimals],
  );

  const validateAddress = useCallback(
    (address: string) => {
      if (!VALID_ADDRESS_REGEX.test(address)) {
        return false;
      }
      if (wallet?.type === 'klip' && wallet?.account === receiverAddress) {
        return false;
      }
      return true;
    },
    [wallet?.type, wallet?.account, receiverAddress],
  );

  const denominatedAmount = useMemo(() => {
    if (!availableMaxAmount) return 0;

    const _denominatedAmount = denominateAmount({
      amount: availableMaxAmount,
      decimals,
    }).replace(/0+$/, '');

    const [_integer, _decimals] = _denominatedAmount.split('.');
    if (!_decimals) return _integer;

    return _denominatedAmount;
  }, [availableMaxAmount, decimals]);

  const handleChange = useCallback(
    (value: string) => {
      const parsed = value
        .replace(/[^\d.]/g, '')
        .replace(/\.([.\d]+)$/, (_, firstMatch) => '.' + firstMatch.replace(/\./g, '')); // Only resolve first dot

      let [integer, decimals] = parsed.split('.');

      // Klip decimal limit in native token transfers
      if (
        wallet?.type === 'klip' &&
        token?.address === ZERO_ADDRESS &&
        typeof decimals !== 'undefined' &&
        decimals.length > 6
      ) {
        decimals = decimals.slice(0, 6);
      }

      const parsedInteger = parseInt(integer, 10);
      const parsedDecimals = parseInt(decimals, 10);

      if (isNaN(parsedInteger)) {
        setTransferAmount(parsed);
        return;
      }
      if (typeof decimals === 'undefined') {
        setTransferAmount(parsedInteger.toLocaleString());
        return;
      }
      if (typeof decimals !== 'undefined' && isNaN(parsedDecimals)) {
        setTransferAmount(parsed);
        return;
      }

      setTransferAmount(`${parsedInteger.toLocaleString()}.${decimals}`);
    },
    [token?.address, wallet?.type],
  );

  const afterTx = useCallback(() => {
    onClose();
  }, [onClose]);

  const tx = useMemo(
    () =>
      wallet?.account && token?.address
        ? {
            name: 'transfer',
            ...(token?.address === ZERO_ADDRESS
              ? {
                  from: wallet?.account,
                  to: receiverAddress,
                  value: numeratedAmount,
                }
              : {
                  from: wallet?.account,
                  to: token?.address,
                  value: 0n,
                  abi: {
                    inputs: [
                      {
                        internalType: 'address',
                        name: 'recipient',
                        type: 'address',
                      },
                      {
                        internalType: 'uint256',
                        name: 'amount',
                        type: 'uint256',
                      },
                    ],
                    name: 'transfer',
                    outputs: [
                      {
                        internalType: 'bool',
                        name: '',
                        type: 'bool',
                      },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                  },
                  params: [receiverAddress, numeratedAmount],
                }),
          }
        : undefined,
    [numeratedAmount, receiverAddress, token?.address, wallet?.account],
  );

  const { send, inFlight } = useWeb3Transaction({
    tx,
    and: {
      then: afterTx,
    },
    wait: true,
  });

  const isTransferDisabled = useMemo(() => {
    if (!token) {
      return true;
    }
    if (!validateAddress(receiverAddress)) {
      return true;
    }
    if (!validateTransferAmount(transferAmount)) {
      return true;
    }
    if (inFlight) {
      return true;
    }
    return false;
  }, [receiverAddress, token, transferAmount, validateTransferAmount, inFlight, validateAddress]);

  const handleTransferClick = useCallback(() => {
    send();
  }, [send]);

  const valuation = useMemo(() => {
    const parsed = parseFloat(transferAmount.replace(/,/g, ''));
    const isEmpty = !transferAmount || isNaN(parsed);
    return usdToReadableCurrency(
      !isEmpty ? (currentPrice ?? 0) * parseFloat(transferAmount.replace(/,/g, '')) : 0,
    );
  }, [currentPrice, transferAmount, usdToReadableCurrency]);

  const [addressBook, setAddressBook, loadFromLocalStorage] = useLocalStorageV2<
    {
      name: string;
      address: string;
    }[]
  >(LOCAL_STORAGE_KEYS.addressBook, []);

  useEffect(() => {
    if (addressBook.length === 1 && addressBook[0].address === '') {
      setAddressBook([]);
    }
  }, [addressBook, setAddressBook]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      const addressBookPopup = document.getElementsByClassName('address-book-popup')[0];

      if (!addressBookPopup) return;

      if (e.key === 'Escape' && addressBookPopup.classList.contains('opacity-100')) {
        setShowAddressbook(false);
        return;
      }

      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', keydownHandler);

    return () => {
      window.removeEventListener('keydown', keydownHandler);
    };
  }, [onClose]);

  const nickname = useMemo(
    () =>
      addressBook.find(({ address }) => address.toLowerCase() === receiverAddress.toLowerCase())
        ?.name ?? '',
    [addressBook, receiverAddress],
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[1500] overflow-y-auto p-4 sm:p-6 md:p-20"
        onClose={() => {
          const addressBookPopup = document.getElementsByClassName('address-book-popup')[0];

          if (addressBookPopup.classList.contains('opacity-100')) return;

          onClose();
        }}
        initialFocus={
          receiverAddressInputRef as unknown as React.MutableRefObject<HTMLElement | null>
        }
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 transition-all bg-dialog-background" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="max-w-[92%] xs:max-w-md p-5 w-full fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform overflow-hidden rounded-xl shadow-2xl transition-all border border-gray-600 bg-gray-900">
            <div className="flex justify-between items-center">
              <label className="font-medium text-lg text-white">{t('Transfer Tokens')}</label>

              <button className="hover:opacity-75" onClick={onClose}>
                <XIcon className="w-6 h-6 text-white" />
              </button>
            </div>

            <div className="flex flex-col justify-center items-center px-4 pt-6 pb-5">
              <div className="shrink-0">
                <TokenIconV2
                  address={token?.address}
                  className="w-20 h-20"
                  width={80}
                  height={80}
                />
              </div>

              <div className="mt-2 flex items-center">
                <span className="font-medium text-lg">{token?.name}</span>
                {token?.address !== ZERO_ADDRESS && (
                  <a
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={tc('{{token}} token contract: View on KaiaScope', {
                      token: token?.symbol,
                    })}
                    href={`https://kaiascope.com/token/${token?.address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-1 flex items-center justify-center hover:opacity-70 text-gray-500"
                  >
                    <LinkIcon />
                  </a>
                )}
              </div>
            </div>

            <div className="flex flex-col">
              <span className={s['input-title']}>{t('Destination Network')}</span>

              <div className="h-12 mt-2 px-4 rounded-lg text-left bg-gray-800">
                <p className={clsx('leading-[48px] select-none', s['input-text'])}>
                  {transferNetwork.toUpperCase()}
                </p>
              </div>

              {/* <button
                  className="h-12 mx-4 px-4 border rounded-lg  text-left mt-1"
                  onClick={() => {
                    setTimeout(() => {
                      if (!token) return;
                      setTokenTransferDialogNotClose(true);
                      openNetworkSelectorDialog({
                        // TODO: setting input chain
                        inputChain: 'kaia',
                        tokenAddress: token.address,
                        setNetworkState: setTransferNetwork,
                      });
                    }, 100);
                  }}
                >
                  {transferNetwork.toUpperCase()}
                </button> */}
            </div>

            <div className="flex flex-col">
              <div className="flex flex-row items-center justify-between relative mt-3">
                <div
                  className={clsx(
                    'flex flex-row items-center space-x-1 overflow-hidden',
                    s['input-title'],
                  )}
                >
                  <span className="whitespace-nowrap">{t('Receiver Address')}</span>

                  {!!nickname && <span className="truncate">{`(${nickname})`}</span>}
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    loadFromLocalStorage([{ name: '', address: '' }]);
                    setShowAddressbook(true);
                  }}
                  className={clsx(
                    'rounded-md px-2 py-1 text-xs font-medium tracking-tight hover:opacity-70 outline-none whitespace-nowrap bg-gray-800 text-gray-300',
                  )}
                >
                  {t('Address book')}
                </button>
              </div>

              <div
                className={clsx(
                  'mt-2 flex items-center px-4 bg-gray-800 rounded-lg',
                  !receiverAddressError ? '' : 'border border-red-700',
                )}
              >
                <ValidatedInput
                  ref={receiverAddressInputRef}
                  className={clsx(
                    'h-12 w-full bg-gray-800 outline-none text-white',
                    s['input-text'],
                  )}
                  placeholder="0x0000...0000"
                  value={receiverAddress}
                  onChange={setReceiverAddress}
                  validate={validateAddress}
                  error={receiverAddressError}
                  setError={setReceiverAddressError}
                />
              </div>
              {receiverAddressError && (
                <span className={s['error-message']}>{t('Recipient address is invalid')}</span>
              )}
              {wallet?.account &&
                wallet.account.toLocaleLowerCase() === receiverAddress.toLocaleLowerCase() && (
                  <span className={s['error-message']}>
                    {t('The wallet you choose is your own')}
                  </span>
                )}
            </div>

            <div className="mt-3 flex flex-col">
              <div className="flex justify-between items-center">
                <label className={s['input-title']}>{t('Amount')}</label>
                <div className="relative inline-flex items-center cursor-pointer mr-2">
                  <input
                    id="max-amount"
                    type="checkbox"
                    className="mr-2 h-4 w-4 rounded bg-gray-700 peer border-none focus:outline-none checked:!bg-teal-600 focus:ring-0"
                    onChange={(event) => {
                      if (!availableMaxAmount) {
                        return;
                      }
                      setMaxAmount(event.target.checked);
                      handleChange(denominatedAmount);
                      setTimeout(() => {
                        setTransferAmountError(false);
                      });
                    }}
                  />
                  <label htmlFor="max-amount" className="text-xs">{`${t(
                    'Max',
                  )} ${denominatedAmount}`}</label>
                </div>
              </div>
              <div
                className={clsx(
                  'mt-2 flex items-center px-4 rounded-lg bg-gray-800',
                  !transferAmountError ? '' : 'border border-red-700',
                )}
              >
                <ValidatedInput
                  ref={transferAmountInputRef}
                  className={clsx(
                    'h-12 px-2 w-full bg-gray-800 outline-none text-right text-white',
                    s['input-text'],
                  )}
                  decimals={decimals}
                  placeholder="0"
                  disabled={maxAmount}
                  value={transferAmount}
                  onChange={handleChange}
                  validate={validateTransferAmount}
                  error={transferAmountError}
                  setError={setTransferAmountError}
                />
                <span>{token?.symbol}</span>
              </div>
              <div className="flex flex-row justify-between mt-2">
                <span className={s['error-message']}>
                  {transferAmountError && t('Token amount is invalid')}
                </span>
                <span className="text-xs font-medium text-gray-400">
                  {tc('~{{amount}}', {
                    amount: valuation,
                  })}
                </span>
              </div>
            </div>

            <p className="mt-4 text-xs font-medium text-gray-400">
              {tc('transmission-warning-message')}
            </p>

            <div className="flex items-center py-4 mt-3">
              <button
                className={clsx(
                  'bg-teal-400 w-full py-4 rounded-xl text-gray-800 text-base font-bold',
                  {
                    'opacity-50 cursor-default': isTransferDisabled,
                    'hover:opacity-70': !isTransferDisabled,
                  },
                )}
                disabled={isTransferDisabled}
                onClick={handleTransferClick}
              >
                {inFlight
                  ? t('Transaction in flight... Check {{provider}}', {
                      provider: wallet?.type,
                    })
                  : t('Send')}
              </button>
            </div>
          </div>
        </Transition.Child>

        <AddressBookPopup
          showAddressbook={showAddressbook}
          setShowAddressbook={setShowAddressbook}
          setReceiverAddress={setReceiverAddress}
          addressBook={addressBook}
        />
      </Dialog>
    </Transition.Root>
  );
}
