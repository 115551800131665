import { Dialog, Transition } from '@headlessui/react';
import { X } from '@phosphor-icons/react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react';

import NumberFormat from '@/components/NumberFormat';

export interface SlippageSetupDialogProps {
  open: boolean;
  slippage: number;
  onSlippageChange: (value: number) => void;
  onClose: () => void;
}

const SLIPPAGE_OPTIONS = [10, 100, 500, 'Custom'];
type SlippageOption = (typeof SLIPPAGE_OPTIONS)[number];
const MAX_SLIPPAGE = 5000;

export default function SlippageSetupDialog({
  open,
  slippage,
  onSlippageChange,
  ...props
}: SlippageSetupDialogProps) {
  const { t } = useTranslation('swap');
  const { t: tc } = useTranslation('common');

  const [innerSlippage, setInnerSlippage] = useState<string | number>('');
  const [selectedOption, setSelectedOption] = useState<SlippageOption | null>(null);
  const slippageInputRef = useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const onClose = useCallback(() => {
    setErrorMsg(null);
    props.onClose();
  }, [props]);

  useEffect(() => {
    if (SLIPPAGE_OPTIONS.includes(slippage)) {
      setSelectedOption(slippage);
    } else {
      setSelectedOption('Custom');
    }

    setInnerSlippage(slippage);
  }, [slippage]);

  const handleValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newSlippageValue = event.target.value.replace(/,/g, '');
    if (!newSlippageValue) {
      setInnerSlippage('');
      return;
    }

    const numberedValue = +newSlippageValue * 100;
    const setSlippageValue = numberedValue > MAX_SLIPPAGE ? MAX_SLIPPAGE : numberedValue;

    if (MAX_SLIPPAGE <= setSlippageValue) {
      setTimeout(() => {
        if (slippageInputRef.current)
          slippageInputRef.current.value = Math.floor(MAX_SLIPPAGE / 100).toString();
      }, 200);
    }

    setInnerSlippage(setSlippageValue);
  }, []);

  const onButtonHandler = useCallback((value: SlippageOption) => {
    setSelectedOption(value);

    if (typeof value !== 'string') {
      setInnerSlippage(value);
    } else {
      // focusing after input enabled
      setTimeout(() => {
        slippageInputRef.current?.focus();

        const valueLength = slippageInputRef.current?.value.length ?? 0;
        slippageInputRef.current?.setSelectionRange(valueLength, valueLength);
      }, 200);
    }

    setErrorMsg(null);
  }, []);

  const onSaveHandler = useCallback(() => {
    if (!innerSlippage) {
      setErrorMsg(tc('Please enter a slippage value'));
      slippageInputRef.current?.focus();

      return;
    }

    if (MAX_SLIPPAGE < innerSlippage) {
      setErrorMsg(
        tc('Slippage value cannot exceed {{maximum}}%', {
          maximum: Math.floor(MAX_SLIPPAGE / 100),
        }),
      );
      slippageInputRef.current?.focus();

      return;
    }

    onSlippageChange(+innerSlippage);
    onClose();
  }, [innerSlippage, onSlippageChange, onClose, tc]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-[1500] overflow-y-auto" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 transition-all bg-dialog-background" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={clsx(
              'bg-gray-900 ring-1 ring-gray-50/5',
              'max-w-xs w-[90%] mx-auto transform overflow-hidden rounded-xl shadow-2xl transition-all fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
            )}
          >
            <div className="w-full flex flex-row items-center justify-between border-b border-b-gray-700 px-4 py-5">
              <label className="font-semibold text-lg text-white">{tc('Slippage')}</label>
              <button className="hover:opacity-70 text-gray-400" onClick={() => onClose()}>
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="p-5 flex flex-col space-y-3">
              <div className="flex items-center space-x-1 justify-between p-[3px] bg-gray-950 rounded-lg">
                {SLIPPAGE_OPTIONS.map((value) => (
                  <button
                    key={`slippage:${value}`}
                    className={clsx(
                      'h-9 px-3 py-2 flex items-center justify-center rounded-lg w-full hover:opacity-70 text-xs font-bold tracking-wider',
                      {
                        'text-white bg-gray-800': value === selectedOption,
                      },
                    )}
                    onClick={() => onButtonHandler(value)}
                  >
                    <span className="mr-1 text-sm">
                      {typeof value === 'string' ? t(value) : `${value / 100}%`}
                    </span>
                  </button>
                ))}
              </div>

              <div className="w-full p-4 flex items-center justify-between space-x-4 bg-gray-950 border border-gray-700 rounded-lg">
                <NumberFormat
                  // @ts-ignore
                  className="p-0 bg-transparent font-medium text-left border-none focus:ring-0 w-full placeholder:text-gray-400 text-white tracking-wider disabled:opacity-70"
                  ref={slippageInputRef}
                  allowNegative={false}
                  decimalScale={2}
                  placeholder="0"
                  value={innerSlippage === '' ? '' : +innerSlippage / 100}
                  onChange={handleValueChange}
                  disabled={selectedOption !== 'Custom'}
                />

                <span className="text-gray-400 font-semibold">%</span>
                <button
                  className="text-teal-400 font-bold hover:opacity-70"
                  onClick={onSaveHandler}
                >
                  {tc('Save')}
                </button>
              </div>

              {errorMsg && (
                <p className="text-red-400 text-sm font-medium text-center">{tc(errorMsg)}</p>
              )}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
