import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '@iconify/react';
import clsx from 'clsx';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Fragment, useCallback, useEffect, useState } from 'react';

import TokenIconV2 from '@/components/TokenIconV2';
import { SCNR_ADDRESS } from '@/defines/token-address';
import useForex from '@/hooks/use-forex';
import usePrices from '@/hooks/use-prices';
import useTokens from '@/hooks/use-tokens';
import { dressTokenCount } from '@/utils/dress-token-count';

import type { Transfer } from '@/lib/transfers';

export interface TransferTokenDialogProps {
  open: boolean;
  transfers: Transfer[];
  onClose: () => void;
}

export default function TransferTokenDialog({
  open,
  transfers,
  ...props
}: TransferTokenDialogProps) {
  const { t } = useTranslation('portfolio');
  const { tokenAddressToToken } = useTokens();
  const [groupedTransfers, setGroupedTransfers] = useState<Transfer[]>([]);
  const { usdToReadableCurrency } = useForex();
  const { prices: tokenPriceMap = {} } = usePrices({
    skip: transfers.length === 0 || !open,
  });

  useEffect(() => {
    if (transfers.length === 0 || !open) return;

    const _groupedTransfers = transfers
      .reduce((acc, transfer) => {
        const existing = acc.find((t) => t.address === transfer.address);
        if (existing) {
          existing.amount += transfer.amount;
        } else {
          acc.push({ ...transfer });
        }
        return acc;
      }, [] as Transfer[])
      .sort((a, b) => {
        const denominateA = a.amount / 10 ** +tokenAddressToToken[a.address].decimals;
        const denominateB = b.amount / 10 ** +tokenAddressToToken[b.address].decimals;

        return (
          (tokenPriceMap[b.address] ?? 0) * denominateB -
          (tokenPriceMap[a.address] ?? 0) * denominateA
        );
      });

    setGroupedTransfers(_groupedTransfers);
  }, [open, transfers, tokenAddressToToken, tokenPriceMap]);

  const onClose = useCallback(() => {
    props.onClose();
  }, [props]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-[1500] overflow-y-auto" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className={clsx('fixed inset-0 transition-all bg-dialog-background')} />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={clsx(
              'bg-gray-900 ring-1 ring-gray-50/5 p-6',
              'max-w-md w-[90%] mx-auto transform overflow-hidden rounded-xl shadow-2xl transition-all fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
            )}
          >
            <div className="w-full flex flex-row items-center justify-between">
              <label className="font-medium text-lg text-slate-200">{t('Interest received')}</label>
              <button
                className="rounded border px-1.5 py-px text-xs font-medium tracking-tight hover:opacity-70 outline-none border-gray-600 bg-gray-900 text-gray-200"
                onClick={() => onClose()}
              >
                ESC
              </button>
            </div>

            <div className="mt-10 flex flex-col items-start space-y-4">
              {groupedTransfers.map(({ address, amount }, idx) => {
                const denominateAmount = amount / 10 ** +tokenAddressToToken[address].decimals;
                const dressedamount = dressTokenCount(denominateAmount);

                return (
                  <div
                    key={`${address}:${idx}`}
                    className="flex items-center space-x-2 justify-between w-full"
                  >
                    <div className="flex items-center space-x-2">
                      <TokenIconV2 address={address} className="w-8 h-8" width={32} height={32} />

                      <span className="font-semibold text-base">
                        {tokenAddressToToken[address].symbol}
                      </span>
                    </div>

                    <div className="flex items-center space-x-4">
                      <div className="flex flex-col items-end">
                        <span>
                          {usdToReadableCurrency(denominateAmount * (tokenPriceMap[address] ?? 0))}
                        </span>
                        <span className="text-sm text-gray-500">{dressedamount}</span>
                      </div>

                      <Link
                        href={`/swap?from=${address}&to=${SCNR_ADDRESS}`}
                        passHref
                        rel="noopener"
                      >
                        <a
                          data-tooltip-id="global-tooltip"
                          data-tooltip-content={t('Go to swap')}
                          rel="noreferrer noopener"
                          onClick={onClose}
                          className="hover:opacity-70"
                        >
                          <Icon icon="eva:swap-fill" className="h-4 w-4 mr-1 shrink-0" />
                        </a>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
