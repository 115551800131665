import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useDisconnect, useWeb3Modal } from '@web3modal/ethers/react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'next-i18next';
import React, { Fragment, KeyboardEvent, useCallback, useEffect, useState } from 'react';

import PrivacyPolicy from '@/components/modal/WalletConnectDialog/PrivacyPolicy';
import TermsOfUse from '@/components/modal/WalletConnectDialog/TermsOfUse';
import DisconnectIcon from '@/components/vector/DisconnectIcon';
import { useKlipContext } from '@/context/klip';
import { providerTypes, useWalletContext } from '@/context/wallet';
import { useWindowSize } from '@/hooks/use-window-size';
import clearWalletConnectIndexedDB from '@/lib/clear-wallet-connect-indexed-DB';
import { dressAddress } from '@/utils/dress-address';
import FeatureFlag from '@/utils/feature-flag';

export type WalletConnectDialogProps = {
  open: boolean;
  onClose: () => void;
};

type TermsType = 'termsOfUse' | 'privacyPolicy' | null;

export const WalletConnectDialog: React.FC<WalletConnectDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation('common');

  const [termsModal, setTermsModal] = useState<{ open: boolean; type: TermsType }>({
    open: false,
    type: null,
  });
  const termsOpenHandler = useCallback(
    (type: Exclude<TermsType, null>) => setTermsModal({ open: true, type }),
    [],
  );
  const termsCloseHandler = useCallback(() => setTermsModal({ open: false, type: null }), []);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [highlightTerms, setHighlightTerms] = useState(false);

  const { requests } = useKlipContext();
  const { wallet, setWallet, walletAvailability, providerType, setProviderType, setKlipAccount } =
    useWalletContext();

  const [userActed, setUserActed] = useState(false);

  const closeHandler = useCallback(() => {
    if (requests.length > 0) return;
    if (termsModal.open) {
      return;
    }

    setAgreeToTerms(false);
    setHighlightTerms(false);
    onClose();
  }, [onClose, requests, termsModal]);

  useEffect(() => {
    if (!wallet || !wallet.account || wallet.type !== providerType) {
      return;
    }

    if (!userActed) {
      return;
    }

    setUserActed(false);
    closeHandler();
  }, [userActed, wallet?.account, wallet, providerType, closeHandler]);

  const { open: web3ModalOpen } = useWeb3Modal();
  const { disconnect } = useDisconnect();

  const { width } = useWindowSize();

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 overflow-y-auto" onClose={closeHandler}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-all bg-dialog-background" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="max-w-[92vw] xs:max-w-md w-full h-fit py-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg rounded-2xl bg-dark-card-color"
            >
              <div className="w-full flex flex-row items-center justify-between">
                <h3 className="px-5 py-4 text-lg">{t('Connect')}</h3>

                <button className="hover:opacity-70 mr-5" onClick={closeHandler}>
                  <XIcon className="w-6 h-6 text-gray-400 hover:opacity-70" />
                </button>
              </div>

              <div className="border-b border-gray-700 px-5 py-4 ">
                <Trans
                  className="text-[15px]"
                  t={t}
                  i18nKey={
                    'Agree to the <TermsOfService>Terms of Service</TermsOfService> and <PrivacyPolicy>Privacy Policy</PrivacyPolicy>'
                  }
                  components={{
                    TermsOfService: (
                      <button
                        className="text-blue-400 hover:opacity-70"
                        onClick={() => termsOpenHandler('termsOfUse')}
                      />
                    ),
                    PrivacyPolicy: (
                      <button
                        className="text-blue-400 hover:opacity-70"
                        onClick={() => termsOpenHandler('privacyPolicy')}
                      />
                    ),
                  }}
                />

                <div className="flex items-center space-x-2 mt-3">
                  <input
                    type="checkbox"
                    id="agreeToTerms"
                    checked={agreeToTerms}
                    onChange={() => {
                      setHighlightTerms(false);
                      setAgreeToTerms(!agreeToTerms);
                    }}
                    className={clsx(
                      'w-[18px] h-[18px] rounded outline-none focus:outline-none focus:ring-0 focus:ring-offset-0',
                      {
                        'checked:text-teal-500': !highlightTerms && agreeToTerms,
                        'border-gray-700 bg-gray-800': !highlightTerms && !agreeToTerms,
                        'border-red-400 border-2 bg-gray-800': highlightTerms,
                      },
                    )}
                  />
                  <label htmlFor="agreeToTerms" className="text-sm cursor-pointer hover:opacity-70">
                    {t('I have read and agree')}
                  </label>
                </div>
              </div>

              <p
                className={clsx('text-lg font-bold px-6 py-4 whitespace-nowrap text-white', {
                  'opacity-50': !agreeToTerms,
                })}
              >
                {t('Select your wallet provider')}
              </p>
              <ul>
                {providerTypes
                  .filter(
                    ({ id }) =>
                      id !== 'walletConnect' ||
                      (FeatureFlag.isWalletConnectEnabled && id === 'walletConnect'),
                  )
                  .map(({ name, id, install, icon }) => {
                    return (
                      <li key={id}>
                        <button
                          type="button"
                          onClick={() => {
                            if (
                              !agreeToTerms &&
                              (id !== wallet?.type || wallet?.type === 'walletConnect')
                            ) {
                              setHighlightTerms(true);
                              return;
                            }

                            disconnect();

                            if (name === 'WalletConnect') {
                              // To resolve the issue of "Error: No matching key. pairing:...",
                              // delete the WalletConnect IndexedDB.
                              clearWalletConnectIndexedDB();

                              setWallet(undefined);
                              setProviderType(id);
                              setUserActed(true);
                              return web3ModalOpen({ view: 'Connect' });
                            }

                            wallet?.type !== id && !walletAvailability[id]
                              ? window.open(install)
                              : walletAvailability[id]
                              ? (() => {
                                  setProviderType(id);
                                  setUserActed(true);
                                })()
                              : undefined;
                          }}
                          className={clsx('hover:opacity-70 w-full  hover:bg-gray-600', {
                            'opacity-50':
                              !agreeToTerms &&
                              (id !== wallet?.type || wallet?.type === 'walletConnect'),
                          })}
                        >
                          <div className="flex flex-row items-center space-x-5 px-6 py-2">
                            <img className="w-8 h-8" src={icon} alt={icon} />
                            <div>
                              <p className="text-base font-normal text-left text-white">{name}</p>
                              <div className="flex items-center space-x-1">
                                {wallet &&
                                'metadata' in wallet &&
                                wallet.metadata.icon &&
                                id === 'walletConnect' ? (
                                  <img
                                    className="w-4 h-4 rounded"
                                    src={wallet.metadata.icon}
                                    alt={wallet.metadata.icon}
                                  />
                                ) : (
                                  <></>
                                )}
                                <p className="text-sm font-normal text-left text-gray-500">
                                  {wallet?.type === id
                                    ? wallet?.account
                                      ? width < 460
                                        ? dressAddress(wallet.account)
                                        : wallet.account
                                      : t('Connecting') + '...'
                                    : walletAvailability[id]
                                    ? t('Available')
                                    : t('Install required')}
                                </p>
                              </div>
                            </div>
                          </div>
                        </button>
                      </li>
                    );
                  })}
                {wallet?.type ? (
                  <li>
                    <button
                      onClick={() => {
                        setProviderType(null);

                        if (wallet?.type === 'walletConnect') {
                          disconnect();
                        } else if (wallet?.type === 'klip') {
                          setKlipAccount(null);
                        }

                        closeHandler();
                      }}
                      className="hover:opacity-70 w-full flex flex-row items-center space-x-5 px-6 py-2 hover:bg-gray-600"
                    >
                      <DisconnectIcon className="w-[25px] text-gray-400" />
                      <p>{t('Disconnect')}</p>
                    </button>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <TermsModal termsModalState={termsModal} termsCloseHandler={termsCloseHandler} />
    </>
  );
};

function TermsModal({
  termsModalState,
  termsCloseHandler,
}: {
  termsModalState: { open: boolean; type: TermsType };
  termsCloseHandler: () => void;
}) {
  const { t } = useTranslation('common');
  const { open, type } = termsModalState;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={termsCloseHandler}
        onKeyUp={(e: KeyboardEvent) => {
          if (e?.key !== 'Escape') return;

          termsCloseHandler();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 transition-all bg-dialog-background" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={clsx(
              'bg-gray-900 ring-1 ring-gray-50/5 p-6',
              'max-w-lg w-[90%] mx-auto transform overflow-hidden rounded-xl shadow-2xl transition-all fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
            )}
          >
            <div className="w-full flex flex-row items-center justify-between">
              {type === 'termsOfUse' ? (
                <h2 className="font-bold text-lg">{t('TERMS OF USE')}</h2>
              ) : type === 'privacyPolicy' ? (
                <h2 className="font-bold text-lg">{t('PRIVACY POLICY')}</h2>
              ) : (
                <></>
              )}
              <button className="hover:opacity-70" onClick={termsCloseHandler}>
                <XIcon className="w-6 h-6 text-gray-400 hover:opacity-70" />
              </button>
            </div>

            <div className="mt-8 flex flex-col items-start space-y-4 max-h-[60vh] overflow-y-auto">
              {type === 'termsOfUse' ? (
                <TermsOfUse />
              ) : type === 'privacyPolicy' ? (
                <PrivacyPolicy />
              ) : (
                <></>
              )}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
